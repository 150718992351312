export default {
    RUMAH: 'RUMAH',
    KERJA: 'KERJA',
    HP: 'HP',
    HP_PRIMARY: 'HP_PRIMARY',
    LAINNYA: 'LAINNYA',
    TIME: 'DT-1',
    TIME_EN: 'DT-2',
    LOWER_CASE: 'LC-1',
    UPPER_CASE: 'UC-1',
    CAPITALIZE_CASE: 'CC-1',
    CAPITALIEZE_CASE_ALL: 'CC-2'
}
